<template>
<div class="page">  
  <var-app-bar title="工单查询" title-position="center" color="#ffffff" text-color="#000" :safe-area-top="true" >
    <template #left>
      <var-button
        color="transparent"
        text-color="#000"
        round
        text
        @click="() => router.go(-1)"
      >
        <var-icon name="chevron-left" :size="24" />
      </var-button>
    </template>
  </var-app-bar>
  <div class="w-full">
    <div class="wrapper">
      <div class="title">方式一：输入工单号</div>
      <div class="row">
        <div class="t">工单编号：</div>
        <input style="height: 40px;border:none; border-bottom: 1px solid #E5E5E5; outline: none; flex: 1" placeholder="请输入工单编号" v-model.trim="workNum" />
      </div>
      <div class="row">
        <div class="t">验证码：</div>
        <input style="height: 40px;border:none; border-bottom: 1px solid #E5E5E5; outline: none"  placeholder="请输入验证码" v-model="code" />
        <div @click="getImg1" v-html="img1" style="width:100px;height: 30px" alt=""></div>
      </div>
      <div class="btn" @click="handleCode">查询</div>
    </div>

    <div class="wrapper">
      <div class="title">方式二：上传工单二维码截图</div>
      <div class="upload-img">
        <div class="wr" @click="handleChooseImg">
          <div class="w1">
            <img v-if="imgUrl" :src="imgUrl" alt="">
            <img v-else src="@/assets/upload-pic.png" style="width:24px;height: 24px" alt="">
          </div>
          <div class="t">工单二维码截图</div>
        </div>
      </div>
      <div class="btn" @click="handleCanfirm">查询</div>
    </div>
  </div>
  <input type="file" style="display: none" ref="input" @change="handleImgChange">
</div>
</template>

<script setup>
  import { ref, onMounted } from 'vue'
  import { useRouter, useRoute } from 'vue-router'
  import { Snackbar } from '@varlet/ui'
  import { getCode, checkCode } from "@/api/account"
  import jsQR from 'jsqr'
  const router = useRouter()
  const workNum = ref('')
  const input = ref(null)
  const imgUrl = ref('')
  const img1 = ref('')
  const key = ref('')
  const code = ref('')
  const route = useRoute()

  const appkey = ref(route.query.appkey)

  const getImg1 = () => {
    getCode().then(res => {
      key.value = res.data.captchaKey
      img1.value = res.data.svg.replace('<svg ', '<svg width="100" height="30" ')
    }) 
  }

  onMounted(() => {
    getImg1()
  })

  // 选择图片
  const handleChooseImg = () => {
    input.value.click()
  }

  // 点击验证码
  const handleCode = () => {
    if (!workNum.value) {
      Snackbar.error('请输入工单编号')
      return
    }
    if (!code.value) {
      Snackbar.error('请输入验证码')
      return
    }
    checkCode({
      captchaKey: key.value,
      captchaValue: code.value
    }).then(res => {
      if (res.code === 0) {
        Snackbar.success('验证成功')
        router.push(`/account?status=2&id=${workNum.value}&appkey=${appkey.value}`)
      } else {
        Snackbar.error('验证失败')
      }
    })
  }

  // 上传图片
  const handleImgChange = (e) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = (e) => {
      const img = new Image()
      img.src = e.target.result
      imgUrl.value = e.target.result
      img.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        canvas.width = img.width
        canvas.height = img.height
        ctx.drawImage(img, 0, 0, img.width, img.height)
        const imageData = ctx.getImageData(0, 0, img.width, img.height)
        const code = jsQR(imageData.data, imageData.width, imageData.height)
        console.log('code is::', code)
        if (code) {
          // 从url中获取查询参数
          const url = code.data
          const index = url.indexOf('?')
          const str = url.slice(index + 1)
          const arr = str.split('&')
          console.log('arr is::', arr)
          const obj = {}
          arr.forEach(item => {
            const [key, value] = item.split('=')
            obj[key] = value
          })
          console.log('obj is::', obj)
          if (obj.id) {
            workNum.value = obj.id
            // handleCanfirm()
          } else {
            Snackbar.error('未识别到有效二维码')
          }
        } else {
          Snackbar.error('未识别到二维码')
        }
        input.value.value = ''
      }
    }
    reader.readAsDataURL(file)
  }

  const handleCanfirm = () => {
    if (!imgUrl.value) {
      Snackbar.error('请上传工单二维码截图')
      return
    }
    if (!workNum.value) {
      Snackbar.error('未识别到有效二维码')
      return
    }
    router.push(`/account?status=2&id=${workNum.value}`)
  }
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 100vh;
  background-color: #F7F8FA;
 
}
.w-full {
  padding: 0 15px;
  box-sizing: border-box;
}
.wrapper {
  margin: 20px auto 0;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
}
.title {
  color: #333;
  font-family: "Noto Sans SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 40px;
  align-items: center;
  margin-top: 15px;
}
.t {
  color: #1C212A;
  font-family: "Noto Sans SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 80px;
}

.btn {
  margin-top: 20px;
  width: 100%;
  height: 50px;
  background-color: #2F88FF;
  border-radius: 8px;
  text-align: center;
  line-height: 50px;
  color: #fff;
}
.upload-img {
  margin: 20px auto 0;
  box-sizing: border-box;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
  .title {
    font-size: 14px;
    font-weight: 600;
    color: #333333;
    text-align: left;
    box-sizing: border-box;
    height: 20px;
  }
  .desc {
    margin-top: 5px;
    font-size: 12px;
    font-weight: 400;
    color: #868E9E;
    text-align: left;
    box-sizing: border-box;
    height: 20px;
  }
  .wr {
    margin-top: 15px;
    margin-right: calc(100%-165px);
    width: 165px;
    height: 145px;
    background-color: #F7F8FA;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .w1 {
      width: 115px;
      height: 80px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .t {
      margin-top: 10px;
      color: #83909D;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      width: 90px;
    }
  }
  .time-w {
    margin-top: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #E5E5E5;
    .span1 {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      text-align: left;
      box-sizing: border-box;
      height: 20px;
      width: 65px;
    }
    input {
      flex: 1;
      width: 70%;
      height: 40px;
      border: none;
      outline: none;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      text-align: left;
      box-sizing: border-box;
      padding: 0;
      margin: 0;
    }
  }
}
</style>
